/*
 *  This file environment.main.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2023
 *  (C) 2015-2023. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *
 */

import { workFlowIds } from '../app/global/constants/workflow.constant';
import { formIds } from '../app/global/constants/forms.contant';
import { ouIds } from '../app/global/constants/organization-units.constant';
import { moduleIds } from '../app/global/constants/modules.contant';

const placeholders = {
  ph1: '/assets/images/image-placeholder.jpg',
  ph2: './assets/images/employee.png',
  ph3: './assets/images/no-image.png'
};

export const environment = {
  firebase: {
    projectId: 'hronev5-pushnotification-prod',
    appId: '1:848700737335:web:3967e516e688eabcc57cb2',
    databaseURL: 'https://hronev5-pushnotification-prod.firebaseio.com',
    storageBucket: 'hronev5-pushnotification-prod.appspot.com',
    locationId: 'asia-south1',
    apiKey: 'AIzaSyAdxbG2m9sAY8Ct-sUO7iuTTORaN_in4qI',
    authDomain: 'hronev5-pushnotification-prod.firebaseapp.com',
    messagingSenderId: '848700737335',
    measurementId: 'G-8T7RXEPLZZ',
  },
  firebase2: {
    apiKey: "AIzaSyCXhR-DLQS58FgSuQ9QrwghL_0GYEY0uKE",  
    authDomain: "hrone-chats-test.firebaseapp.com",   
    projectId: "hrone-chats-test",  
    storageBucket: "hrone-chats-test.appspot.com",  
    messagingSenderId: "715726292370",
    appId: "1:715726292370:web:58c8f62b7e867bae3fad4b"
  },
  production: false,
  workFlowIds: workFlowIds,
  formIds: formIds,
  ouIds: ouIds,
  moduleIds: moduleIds,
  placeholders: placeholders,
  placeholder_xs: '/assets/images/image-placeholder.jpg',
  placeholder_sm: '/assets/images/image-placeholder.jpg',
  placeholder_md: '/assets/images/image-placeholder.jpg',
  apiVersion: 'v1',
  typingEvent: [91, 8, 9],
  users: {
    skip: 0,
    limit: 10
  },
  messages: {
    skip: 0,
    limit: 15
  }
  ,
  envName: "production",
  domainCode: "hronenew-testing",
  base_url: "https://test-api.hrone.work",
  base_auth_url: "https://test-gateway.hrone.work",
  stripe_base_url:"https://hronedev-subscription.azurewebsites.net",
  isDeployOnGT: true,
  power_bi_url:"https://dev-powerbi.hrone.work",
  microservice_base_url:"https://test-gateway.hrone.work",
  broadcastUrl: "https://dev-notifications.hrone.work/hubs/presence",
  hropsDeploymentEnable: true,
  isKBEnable: true,
  isGDPRPointerEnabled: true,
  isChatEnabled:false
};
